@import "@/styles";

$animation-duration: 400ms;

.container {
  padding: 15rem 0;
  @include flex-center();

  @include mobile {
    padding: 10rem 0;
  }

  .image {
    position: relative;
    width: 40rem;
    height: 40rem;
    @include mobile {
      width: 30rem;
      height: 30rem;
    }
  }
}
